import { ref, reactive, watch, unref } from "vue";
import { TableData } from "@/views/Checking/spotChecking/spotCheckingControlling/allClaimsReview/claimTracking/types";
import * as API from "@/API/checking/spotChecking";
import downloadFile from '@/utils/payment/downloadFile';
import { message } from 'ant-design-vue';
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import { getRoundListByDate } from '@/API/payment';
const allClaimsReview = () => {
  const query = reactive({
    dealerCode: "",
    dealerNameEn: "",
    programCode: "",
    programName: "",
    typeClass: "",
    model: "",
    vin: "",
    checkResult: "All",
    appeal: "",
    payoutRound: "All",
  });

  const activeTab = ref('Dealer details')
  // const tabs = ['To Dealer', 'To WS', 'To RT']
  const tabs = ['Dealer details', 'F&L RT', 'F&L WS']
  const payoutRoundOptions = ref<string[]>()
  const getPayountRoundOptions = () => {
    if (activeTab.value === 'To Dealer') {
      const params = {
        year: '0',
        month: '0',
        type: 'DEALER'
      }
      getRoundListByDate({ params }).then(data => {
        payoutRoundOptions.value = data
      })
    }
  }
  
  const checkResultOptions = ref<any[]>([
    {
      name: "Uncheck",
      value: "0",
    },
    {
      name: "Approved",
      value: "1",
    },
    {
      name: "Rejected",
      value: "2",
    },
    {
      name: "Pending",
      value: "3",
    }
  ])
  const originColumn = ref([
    { title: "Vin Number", dataIndex: "vinNumber",width:170 },
    { title: "Dealer Code", dataIndex: "dealerCode", dynamic: true },
    { title: "Dealer Name(EN)", dataIndex: "dealerNameEn", dynamic: true,width:200},
    { title: "Program Code", dataIndex: "programCode" },
    { title: "Program Name", dataIndex: "programName",width:150 },
    { title: "Offer Type", dataIndex: "offerType" },
    { title: "Type Class", dataIndex: "typeClass" },
    { title: "Model", dataIndex: "model" },
    { title: "Payout Round", dataIndex: "payoutRound" },
    { title: "Check Result", dataIndex: "checkResult",width:100 },
    { title: "Appeal", dataIndex: "appeal", appealDynamic: true,width:80 },
    { title: "Claim Amount", dataIndex: "claimAmount", customRender: useThousandsAndTwoDecimal(), align: 'right' },
    { title: "Payout Amount", dataIndex: "payoutAmount", customRender: useThousandsAndTwoDecimal(), align: 'right' },
    { title: "Update Time", dataIndex: "updateTime", slots: { customRender: 'updateTime' } },
  ]);
  const column = ref(unref(originColumn))
  const tableData = ref<TableData[]>();
  const total = ref(0)
  const pagination = reactive({
    pageNumber: 0,
    pageSize: 10,
  })
  const getDealerList = () => {
    tableData.value = []
    total.value = 0
    const params = {
      dealerCode: query.dealerCode ? query.dealerCode : undefined,
      dealerNameEn: query.dealerNameEn ? query.dealerNameEn : undefined,
      programCode: query.programCode ? query.programCode : undefined,
      programName: query.programName ? query.programName : undefined,
      typeClass: query.typeClass ? query.typeClass : undefined,
      model: query.model ? query.model : undefined,
      vin: query.vin ? query.vin : undefined,
      checkResult: query.checkResult !== 'All' ? query.checkResult : undefined,
      appeal: query.appeal ? query.appeal : undefined,
      payoutRound: query.payoutRound !== 'All' ? query.payoutRound : undefined,
      pageSize: pagination.pageSize,
      pageIndex: pagination.pageNumber
    }
    API.getClaimCheckingDealerList(params).then(data => {
      tableData.value = data.content
      total.value = data.totalElements
    })
  }
  const getWSList = () => {
    tableData.value = []
    total.value = 0
    const params = {
      dealerCode: query.dealerCode ? query.dealerCode : undefined,
      dealerNameEn: query.dealerNameEn ? query.dealerNameEn : undefined,
      programCode: query.programCode ? query.programCode : undefined,
      programName: query.programName ? query.programName : undefined,
      typeClass: query.typeClass ? query.typeClass : undefined,
      model: query.model ? query.model : undefined,
      vin: query.vin ? query.vin : undefined,
      checkResult: query.checkResult !== 'All' ? query.checkResult : undefined,
      appeal: query.appeal ? query.appeal : undefined,
      payoutRound: query.payoutRound !== 'All' ? query.payoutRound : undefined,
      pageSize: pagination.pageSize,
      pageIndex: pagination.pageNumber
    }
    API.getClaimCheckingWSList(params).then(data => {
      tableData.value = data.content
      total.value = data.totalElements
    })
  }
  const getRTList = () => {
    tableData.value = []
    total.value = 0
    const params = {
      dealerCode: query.dealerCode ? query.dealerCode : undefined,
      dealerNameEn: query.dealerNameEn ? query.dealerNameEn : undefined,
      programCode: query.programCode ? query.programCode : undefined,
      programName: query.programName ? query.programName : undefined,
      typeClass: query.typeClass ? query.typeClass : undefined,
      model: query.model ? query.model : undefined,
      vin: query.vin ? query.vin : undefined,
      checkResult: query.checkResult !== 'All' ? query.checkResult : undefined,
      appeal: query.appeal ? query.appeal : undefined,
      payoutRound: query.payoutRound !== 'All' ? query.payoutRound : undefined,
      pageSize: pagination.pageSize,
      pageIndex: pagination.pageNumber
    }
    API.getClaimCheckingRTList(params).then(data => {
      tableData.value = data.content
      total.value = data.totalElements
    })
  }
  const getTableData = () => {
    switch (activeTab.value) {
      case 'Dealer details':
        getDealerList()
        break
      case 'F&L RT':
        getRTList()
        break
      case 'F&L WS':
        getWSList()
        break
    }
  };

  const exportDealer = () => {
    const params = {
      url: '/claimapi/claimTracking/dealerExport',
      method: 'post',
      params: {
        dealerCode: query.dealerCode ? query.dealerCode : undefined,
        dealerNameEn: query.dealerNameEn ? query.dealerNameEn : undefined,
        programCode: query.programCode ? query.programCode : undefined,
        programName: query.programName ? query.programName : undefined,
        typeClass: query.typeClass ? query.typeClass : undefined,
        model: query.model ? query.model : undefined,
        vin: query.vin ? query.vin : undefined,
        checkResult: query.checkResult !== 'All' ? query.checkResult : undefined,
        appeal: query.appeal ? query.appeal : undefined,
        payoutRound: query.payoutRound !== 'All' ? query.payoutRound : undefined,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageNumber
      }
    }
    downloadFile(params).catch((msg: any) =>
        message.warning(msg)
    )
  }
  const exportWS = () => {
    const params = {
      url: '/claimapi/claimTracking/flWsexport',
      method: 'post',
      params: {
        dealerCode: query.dealerCode ? query.dealerCode : undefined,
        dealerNameEn: query.dealerNameEn ? query.dealerNameEn : undefined,
        programCode: query.programCode ? query.programCode : undefined,
        programName: query.programName ? query.programName : undefined,
        typeClass: query.typeClass ? query.typeClass : undefined,
        model: query.model ? query.model : undefined,
        vin: query.vin ? query.vin : undefined,
        checkResult: query.checkResult !== 'All' ? query.checkResult : undefined,
        appeal: query.appeal ? query.appeal : undefined,
        payoutRound: query.payoutRound !== 'All' ? query.payoutRound : undefined,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageNumber
      }
    }
    downloadFile(params).catch((msg: any) =>
        message.warning(msg)
    )
  }
  const exportRT = () => {
    const params = {
      url: '/claimapi/claimTracking/flRtexport',
      method: 'post',
      params: {
        dealerCode: query.dealerCode ? query.dealerCode : undefined,
        dealerNameEn: query.dealerNameEn ? query.dealerNameEn : undefined,
        programCode: query.programCode ? query.programCode : undefined,
        programName: query.programName ? query.programName : undefined,
        typeClass: query.typeClass ? query.typeClass : undefined,
        model: query.model ? query.model : undefined,
        vin: query.vin ? query.vin : undefined,
        checkResult: query.checkResult !== 'All' ? query.checkResult : undefined,
        appeal: query.appeal ? query.appeal : undefined,
        payoutRound: query.payoutRound !== 'All' ? query.payoutRound : undefined,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageNumber
      }
    }
    downloadFile(params).catch((msg: any) =>
        message.warning(msg)
    )
  }
  const exportClick = () => {
    switch (activeTab.value) {
      case 'Dealer details':
        exportDealer()
        break
      case 'F&L RT':
        exportRT()
        break
      case 'F&L WS':
        exportWS()
        break
    }
  };

  //#region 分页
  const pageChange = (page: number, size: number) => {
    pagination.pageNumber = page > 0 ? page : 1
    pagination.pageSize = size
    getTableData()
  };
  const sizeChange = (page: number, size: number) => {
    pagination.pageNumber = page > 0 ? page : 1
    pagination.pageSize = size
    getTableData()
  }
  //#endregion

  //#region 切换tab
  const handleTabChange = () => {
    query.payoutRound = 'All'
    payoutRoundOptions.value = []
    getPayountRoundOptions()
    pagination.pageNumber = 1
    pagination.pageSize = 10
    getTableData()
  }
  //#endregion
  
  //#region 查询和重置
  const doSearch = () => {
    pagination.pageNumber = 1
    getTableData()
  }

  const doReset = () => {
    Object.assign(query, {
      dealerCode: "",
      dealerNameEn: "",
      programCode: "",
      programName: "",
      typeClass: "",
      model: "",
      vin: "",
      checkResult: "All",
      appeal: "",
      payoutRound: "All",
    })
    getTableData()
  }
  //#endregion
  watch(activeTab, (val: string)=> {
    if (val === 'F&L RT') {
      column.value = originColumn.value.filter((item: any) => {
        return item.dynamic !== true && item.appealDynamic !== true
      })
    } else if (val === 'F&L WS') {
      column.value = originColumn.value.filter((item: any) => {
        return item.appealDynamic !== true
      })
    } else {
      column.value = originColumn.value
    }
  })
  return {
    query,
    getPayountRoundOptions,
    payoutRoundOptions,
    checkResultOptions,
    activeTab,
    tabs,
    handleTabChange,
    column,
    tableData,
    pagination,
    total,
    pageChange,
    sizeChange,
    doSearch,
    doReset,
    exportClick
  };
};
export default allClaimsReview;
