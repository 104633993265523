
import { defineComponent, reactive, ref, toRefs } from "vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import searchTitle from "./searchTitle.vue";
import useClaimTracking from "@/hooks/checking/spotChecking/allClaimsReview/claimTracking/useClaimTracking";

const tableHeight = window.innerHeight-460
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const isOpen = ref<boolean>(false);
    const {
      query,
      getPayountRoundOptions,
      payoutRoundOptions,
      checkResultOptions,
      column,
      activeTab,
      tabs,
      handleTabChange,
      tableData,
      total,
      pagination,
      pageChange,
      sizeChange,
      doSearch,
      doReset,
      exportClick
    } = useClaimTracking();
    const handlePageChange = (page: number,pageSize: number) =>{
      pageChange(page, pageSize)
    }
    const handleSizeChange = (page: number,pageSize: number) =>{
      sizeChange(page, pageSize)
    }
    getPayountRoundOptions();
    doSearch();

    const rowClass = (record: unknown, index: number) => (index % 2 === 1 ? 'table-striped' : null)

    return {
      ...toRefs(query),
      isOpen,
      payoutRoundOptions,
      checkResultOptions,
      activeTab,
      tabs,
      handleTabChange,
      column,
      tableData,
      total,
      ...toRefs(pagination),
      isPagination: true,
      tableHeight,
      rowClass,
      handlePageChange,
      handleSizeChange,
      doSearch,
      doReset,
      exportClick
    };
  },
  components: {
    searchTitle,
    TableWrapper,
  },
});
